<template>
  <span>
    <loading-gif short :loading-name="loadingName"></loading-gif>
    <div v-if="!isLoading(loadingName)">
      <div :class="blockClass" v-show="forcedYouthHome === 0">
        <b-form-group
          :label="youthHomeLabel"
          label-for="youthHomeSelect"
          description=""
        >
          <check-box-select
            :choices="youthHomes"
            @changed="onYouthHomesChanged($event)"
            id="youthHomeSelect"
          ></check-box-select>
        </b-form-group>
      </div>
      <div :class="blockClass">
        <b-form-group
          v-if="seanceTypes.length"
          label="Types de séances"
          label-for="seanceTypesSelect"
          description=""
        >
          <check-box-select
            :choices="seanceTypes"
            @changed="onSeanceTypesChanged($event)"
            id="seanceTypesSelect"
          ></check-box-select>
        </b-form-group>
      </div>
      <div :class="blockClass">
        <b-form-group
          v-if="periods.length"
          label="Périodes"
          label-for="periodsSelect"
          description=""
        >
          <check-box-select
            :choices="periods"
            @changed="onPeriodsChanged($event)"
            id="periodsSelect"
          ></check-box-select>
        </b-form-group>
      </div>
    </div>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { BackendMixin } from '@/mixins/backend'
import { mapActions, mapMutations } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import LoadingGif from '@/components/Controls/LoadingGif'
import { makeSeancePeriod, makeSeanceType, makeYouthHome } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { existsIn } from '@/utils/arrays'
import { compareNumbers } from '@/utils/sorting'
import store from '@/store'

export default {
  name: 'YouthHomeFilter',
  components: {
    CheckBoxSelect,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    forcedYouthHome: {
      type: Number,
      default: 0,
    },
    schoolYear: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedYouthHomes: [],
      selectedSeanceTypes: [],
      selectedPeriods: [],
      allYouthHomes: [],
      allSeanceTypes: [],
      allPeriods: [],
      currentYear: null,
    }
  },
  watch: {
    selectedYouthHomes: function() {
      this.emitChanged()
    },
    selectedSeanceTypes: function() {
      this.emitChanged()
    },
    selectedPeriods: function() {
      this.emitChanged()
    },
    forcedYouthHome: function() {
      this.selectForceYouthHomes()
    },
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    loadingName() {
      return 'youth-home-filter'
    },
    blockClass() {
      return this.block ? 'block' : 'inline-block'
    },
    seanceTypes() {
      if (this.selectedYouthHomes.length) {
        const youthHomeNumbers = this.selectedYouthHomes.map(elt => elt.number)
        return this.allSeanceTypes.filter(elt => {
          return (
            (elt.youthHomes.length === 0) || existsIn(elt.youthHomes, youthHomeNumbers)
          )
        })
      } else {
        return []
      }
    },
    periods() {
      let periods = []
      if (this.selectedYouthHomes.length && this.selectedSeanceTypes.length) {
        const youthHomeNumbers = this.selectedYouthHomes.map(elt => elt.number)
        periods = this.allPeriods.filter(elt => {
          return (
            (elt.youthHomes.length === 0) || existsIn(elt.youthHomes, youthHomeNumbers)
          )
        }).filter(elt => {
          const typeNames = this.selectedSeanceTypes.map(elt => elt.name)
          return (
            (elt.seanceTypes.length === 0) || existsIn(elt.seanceTypes, typeNames)
          )
        }).sort(
          (elt1, elt2) => {
            let schoolYearId = this.selectedYouthHomes[0].schoolYear.id
            let timeframe1 = elt1.getTimeframe(schoolYearId)
            let timeframe2 = elt2.getTimeframe(schoolYearId)
            let dateFrom1 = null
            let dateFrom2 = null
            if (timeframe1.length >= 2 && timeframe2.length >= 2) {
              dateFrom1 = timeframe1[0]
              dateFrom2 = timeframe2[0]
            }
            if (dateFrom1 < dateFrom2) {
              return -1
            } else if (dateFrom1 > dateFrom2) {
              return 1
            } else {
              return 0
            }
          }
        )
      }
      return periods
    },
    youthHomes() {
      let youthHomes = this.allYouthHomes
      if (this.schoolYear) {
        youthHomes = youthHomes.filter(elt => elt.schoolYear.id === this.schoolYear.id)
      }
      youthHomes = youthHomes.sort(
        (elt1, elt2) => {
          return compareNumbers(-elt1.schoolYear.startYear, -elt2.schoolYear.startYear)
        }
      )
      return youthHomes
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    selectForceYouthHomes() {
      if (this.forcedYouthHome) {
        const matching = this.youthHomes.filter(elt => elt.id === (+this.forcedYouthHome))
        this.selectedYouthHomes = matching
      } else {
        this.selectedYouthHomes = []
      }
    },
    emitChanged() {
      this.$emit(
        'changed',
        {
          youthHomes: this.selectedYouthHomes,
          seanceTypes: this.selectedSeanceTypes,
          periods: this.selectedPeriods,
        }
      )
    },
    async onLoaded() {
      this.startLoading(this.loadingName)
      await this.loadYouthHomes()
      await this.loadSeanceTypes()
      await this.loadPeriods()
      this.$emit(
        'loaded', { youthHomes: this.youthHomes, seanceTypes: this.seanceTypes, periods: this.periods, }
      )
      this.endLoading(this.loadingName)
    },
    async loadYouthHomes() {
      let url = '/api/youth/active-youth-homes/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allYouthHomes = resp.data.map(elt => makeYouthHome(elt))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async loadSeanceTypes() {
      let url = '/api/youth/seance-types/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allSeanceTypes = resp.data.map(elt => makeSeanceType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadPeriods() {
      let url = '/api/youth/seance-periods/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allPeriods = resp.data.map(elt => makeSeancePeriod(elt))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    onYouthHomesChanged(event) {
      this.selectedYouthHomes = event.choices
    },
    onSeanceTypesChanged(event) {
      this.selectedSeanceTypes = event.choices
    },
    onPeriodsChanged(event) {
      this.selectedPeriods = event.choices
    },
  },
  async mounted() {
    await this.onLoaded()
    if (this.forcedYouthHome) {
      this.selectForceYouthHomes()
    }
  },
}
</script>
<style lang="less">
.inline-block {
  display: inline-block;
  width: calc(33% - 30px);
  vertical-align: top;
  margin-right: 30px;
}
</style>
